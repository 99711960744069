import { useState } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { Switch } from '../../components/Switch';
import { SurveyResultSection } from './elements/SurveyResultSection';
import { useGroupVisibilityToggle } from '../../hooks/useGroupVisiblityToggle';

const filterByScore = (el: { score: undefined | null | number }) =>
    el.score === undefined || el.score === null || el.score > 0;

interface Props {
    sections: {
        section: string;
        score?: number;
        questions: {
            text: string;
            hint: string[] | string[][];
            files: { name: string, content: string, type: string }[];
            score: undefined | null | number;
        }[];
    }[];
}

export const SurveyResult = (props: Props) => {
    const [showAll, setShowAll] = useState(true);

    const sections = showAll
        ? props.sections
        : props.sections.filter((el) => el.questions.filter(filterByScore).length > 0);

    const hasScore = sections.some(({ questions }) => questions.some(({ score }) => typeof score === 'number'));
    const hasExpansion = sections.length > 1;

    const [expandedSections, toggleSectionVisibility] = useGroupVisibilityToggle<number>([]);

    return (
        <div className='mt-8 flex flex-col gap-4'>
            {hasScore && (
                <label className='flex gap-3 items-center select-none'>
                    <Switch checked={!showAll} onChange={(value) => setShowAll(!value)} />

                    <span className='text-sm font-normal text-slate-800'>
                        Hide questions with no impact on the score
                    </span>
                </label>
            )}

            <div className='overflow-hidden ring-opacity-5 md:rounded-lg flex flex-col gap-4 pb-4'>
                {sections.map((section, index) => (
                    <div key={index} className='bg-basic/10 rounded-md shadow flex flex-col gap-5 cursor-pointer'>
                        <div className='flex flex-row p-4 justify-between items-center' onClick={() => toggleSectionVisibility(index)}>
                            {hasExpansion && (
                                <div className='flex gap-3 items-center'>
                                    {
                                        expandedSections.includes(index)
                                            ? <MinusIcon className="h-5 w-5" />
                                            : <PlusIcon className="h-5 w-5" />
                                    }

                                    <div className='font-normal text-slate-800'>
                                        {section.section}
                                    </div>
                                </div>
                            )}

                            {(hasScore && typeof section.score === 'number') && (
                                <div className='font-normal text-slate-800'>
                                    Score: {section.score}
                                </div>
                            )}
                        </div>

                        {(!hasExpansion || expandedSections.includes(index)) && (
                            <SurveyResultSection items={section.questions} showAll={showAll} score={section.score} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
